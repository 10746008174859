.App{
    background-color: black;
    height: 100vh;
}

.btn-warning{
    background-color: #E6007E !important;
    border: #E6007E !important;
}
.btn-light{
    background-color: #FDE900 !important;
    border: #FDE900 !important;
}